@import "./headings";
@import "./buttons";

@mixin rte($includeColor: true, $full: true) {
  @include typeBody;

  @include above(md) {
    @include typeBody(20, 400);
  }

  @if ($includeColor) {
    color: token("primary-on-general-light");
  }

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  b,
  strong,
  .bold {
    font-weight: 600;
  }

  i,
  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  a {
    text-decoration: underline;

    @if ($includeColor) {
      color: token("interaction-on-general-light");
    }
  }

  p {
    margin-bottom: rem(16);

    @include above(md) {
      margin-bottom: rem(20);
    }

    text-wrap: pretty;
  }

  @if ($full) {

    h1,
    h2 {
      @include typeHeadline(28);
      margin-bottom: rem(24);

      @include above(md) {
        margin-bottom: rem(32);
      }
    }

    h3,
    h4 {
      margin-bottom: rem(16);

      @include above(md) {
        margin-bottom: rem(24);
      }
    }

    h3 {
      @include typeHeadline(20, "bold");
    }

    h4,
    h5 {
      @if ($includeColor) {
        color: token("secondary-on-general-light");
      }
    }

    h4 {
      @include typeHeadline(20, "bold");
    }

    h5 {
      @include typeHeadline(16, "bold");

      @include above(md) {
        @include typeHeadline(20, "bold");
      }
    }

    h5,
    h6 {
      margin-bottom: rem(16);

      @include above(md) {
        margin-bottom: rem(20);
      }
    }

    ul,
    ol {
      margin-left: rem(16);
      margin-bottom: rem(16);
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
      padding-left: rem(8);

      li+li {
        margin-top: rem(2);
      }
    }

    ol {
      counter-reset: list-counter;

      li+li {
        margin-top: rem(8);
      }

      li {
        counter-increment: list-counter;

        &::before {
          @include typeLabel(12, "bold");
          display: inline-flex;
          align-items: center;
          justify-content: center;
          content: counter(list-counter);
          margin-right: rem(12);
          min-width: rem(24);
          min-height: rem(24);
          background-color: token("general-accent");
          border-radius: rem(28);
        }
      }

      &[style*="list-style-type"] {
        list-style-position: inside;
        padding-left: rem(8);

        li {
          &::before {
            display: none;
          }
        }
      }
    }

    blockquote {
      margin-bottom: rem(16);
      border-left: solid rem(2) token("general-divide-feature-2");
      padding-left: rem(16);
    }

    [data-table-wrapper] {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: rem(16);
    }

    table {
      caption {
        @include typeLabel(16, "bold");
        margin-bottom: rem(12);
        text-align: left;
      }

      th,
      td {
        border: solid 1px token("general-divide-light");
        border-collapse: collapse;
        padding: rem(12) rem(24);
      }

      thead {
        text-align: center;

        tr {
          background-color: token("general-dark");
          color: token("primary-on-general-dark");
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: token("general-accent");
          }
        }
      }
    }

    img {
      float: left;
      max-width: 100%;
      height: auto;
      padding-right: rem(16);

      &:last-child {
        padding-right: 0;
      }
    }

    figure {
      margin-bottom: rem(16);

      img {
        float: none;
        padding: 0;
      }
    }

    figcaption {
      @include typeLabel(12, "bold");
      margin-top: rem(12);
      border-left: solid rem(2) token("general-divide-feature-2");
      padding-left: rem(16);
    }

    pre {
      max-width: 100%;
      white-space: normal;
    }

    code {
      display: inline-block;
      margin-bottom: rem(16);
      padding: rem(8);
      background-color: token("general-accent");
      font-family: monospace;
      font-display: swap;
    }

    sub,
    sup {
      font-size: smaller;
    }

    sub {
      vertical-align: sub;
    }

    sup {
      vertical-align: super;
    }

    hr {
      border-color: token("general-divide-dark");
      margin: rem(16) 0;

      @include above(md) {
        margin: rem(20) 0;
      }
    }

    a {
      &[data-button-primary] {
        @extend %button, %button-primary;
        text-decoration: none;

        &:hover,
        &:focus-visible {
          text-decoration: underline;
          background-color: transparentize(token("interaction-primary"), 0.4);
        }
      }

      &[data-button-secondary] {
        @extend %button, %button-secondary;
        text-decoration: none;
      }
    }
  }

  :last-child {
    margin-bottom: 0;

    @include above(md) {
      margin-bottom: 0;
    }
  }
}